<template>
  <section class="homepage-forexlive__wrapper">
    <div class="homepage-forexlive__forexlive-logo-wrapper">
      <a-link
        :to="this.$env.FL_DOMAIN_URL"
        open-in-new-tab
        class="homepage-forexlive__powered-by"
      >
        Powered by
      </a-link>
      <a-icon v-bind="$options.consts.flLogoIconProps" class="forexlive-icon" />
    </div>
    <forexlive-latest-news
      v-if="isDataValid.ForexliveLatestNews"
      :articles="latestNews"
      :last-modified="lastModifiedByRequest.ForexliveLatestNews"
      class="homepage-forexlive__latest-news"
    />
    <forexlive-technical-analysis
      v-if="isDataValid.ForexliveTechnicalAnalysisNews"
      :articles="technicalAnalysisNews"
      :last-modified="lastModifiedByRequest.ForexliveTechnicalAnalysis"
      class="homepage-forexlive__technical-analysis"
    />
  </section>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import ForexliveLatestNews from './ForexliveLatestNews'
import ForexliveTechnicalAnalysis from './ForexliveTechnicalAnalysis'
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'HomepageForexlive',
  components: { AIcon, ForexliveLatestNews, ForexliveTechnicalAnalysis },
  props: {
    latestNews: propValidator([PROP_TYPES.ARRAY]),
    technicalAnalysisNews: propValidator([PROP_TYPES.ARRAY]),
    lastModifiedByRequest: propValidator([PROP_TYPES.OBJECT]),
    isDataValid: propValidator([PROP_TYPES.OBJECT])
  },
  consts: {
    flLogoIconProps: {
      icon: ICON.FL,
      width: 120,
      original: true
    }
  }
}
</script>

<style scoped lang="scss">
.homepage-forexlive__wrapper {
  .homepage-forexlive__forexlive-logo-wrapper {
    display: flex;
    align-items: center;
  }

  .homepage-forexlive__powered-by {
    $font-size: (
      $above-desktop-xl: 28px,
      $mobile: 22px
    );
    @include setStyleByBreakpoint('font-size', $font-size);
    font-weight: 700;
  }

  .forexlive-icon {
    margin-left: 6px;
    margin-bottom: 4px;
  }

  .homepage-forexlive__latest-news {
    margin-top: 20px;
  }

  .homepage-forexlive__technical-analysis {
    margin-top: 35px;
  }
}
</style>
