<template>
  <component :is="tag">
    <template v-for="(item, index) in alwaysVisibleItems">
      <slot :item="item" :index="index" />
    </template>
    <template v-for="(settings, index) in partiallyVisibleSettings">
      <a-visibility
        :key="settings.visibleOn.join('')"
        show
        :on="settings.visibleOn"
      >
        <slot
          :item="settings.item"
          :index="index + lastAlwaysVisibleItemNumber"
        />
      </a-visibility>
    </template>
  </component>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { HTML_TAG } from '@fmpedia/enums'
import { Breakpoint } from '@/utils/scss'

export default {
  name: 'AResponsiveFor',
  props: {
    items: propValidator([PROP_TYPES.ARRAY]),
    tag: propValidator([PROP_TYPES.STRING], false, HTML_TAG.DIV),
    countByBreakpoint: propValidator([PROP_TYPES.OBJECT])
  },
  computed: {
    lastAlwaysVisibleItemNumber() {
      return Math.min(...Object.values(this.countByBreakpoint))
    },
    alwaysVisibleItems() {
      return this.items.slice(0, this.lastAlwaysVisibleItemNumber)
    },
    partiallyVisibleItems() {
      return this.items.slice(this.lastAlwaysVisibleItemNumber)
    },
    partiallyVisibleSettings() {
      return this.partiallyVisibleItems.map((item, index) => {
        const itemNumber = index + 1 + this.lastAlwaysVisibleItemNumber

        let lastCount
        const visibleBreakpoints = Breakpoint.breakpointNamesByRenderOrdering.filter(
          breakpointName => {
            const breakpoint = new Breakpoint({ name: breakpointName })
            const expectedCount =
              this.countByBreakpoint[breakpoint.value] || lastCount || null

            if (!expectedCount) return false

            return itemNumber <= expectedCount
          }
        )

        return {
          visibleOn: visibleBreakpoints.map(Breakpoint.getValueByName),
          item
        }
      })
    }
  }
}
</script>
