<template>
  <div class="homepage-forexlive-technical-analysis__wrapper">
    <a-section-title
      title="Technical Analysis"
      :link="linkToFlTechnicalAnalysis"
      class="homepage-forexlive-technical-analysis__title"
    />
    <a-responsive-for
      :items="articles"
      :count-by-breakpoint="$options.consts.articleCountSettings"
      class="homepage-forexlive-technical-analysis__article-list"
    >
      <template v-slot="{ item }">
        <article-slot :key="item.Id" :article="item" />
      </template>
    </a-responsive-for>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import mixins from '@/utils/mixins'
import ASectionTitle from '@/components/_homepage/Shared/ASectionTitle'
import scss from '@/utils/scss'
import AResponsiveFor from 'shared/AResponsiveFor'
import { MIGRATED_CATEGORY_SLUG } from 'enums/fl/fl-categories'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

const { breakpoints } = scss

export default {
  name: 'HomepageForexliveTechnicalAnalysis',
  mixins: [serverCacheKey, mixins.urlGenerators],
  components: {
    AResponsiveFor,
    ASectionTitle,
    ArticleSlot: hydrateWhenVisible(
      () =>
        import('shared/ArticleSlots/Fl/Homepage/AArticleSlotTechnicalAnalysis'),
      { props: ['article'] }
    )
  },
  props: {
    articles: propValidator([PROP_TYPES.ARRAY])
  },
  consts: {
    articleCountSettings: {
      [breakpoints.aboveDesktopXl]: 3,
      [breakpoints.desktopXl]: 3,
      [breakpoints.desktopLg]: 3,
      [breakpoints.desktopMd]: 4,
      [breakpoints.desktopSm]: 4,
      [breakpoints.tablet]: 4,
      [breakpoints.mobile]: 2
    }
  },
  computed: {
    linkToFlTechnicalAnalysis() {
      return this.generateFlCategoryUrl(
        MIGRATED_CATEGORY_SLUG.TECHNICAL_ANALYSIS
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';
.homepage-forexlive-technical-analysis__wrapper {
  .homepage-forexlive-technical-analysis__title
    /deep/
    .homepage-section-title__title {
    color: $c--fl-main;
  }

  .homepage-forexlive-technical-analysis__article-list {
    $grid-setup: (
      $columnSetup: (
        $above-desktop-xl: repeat(3, minmax(0, 100%)),
        $desktop-md: repeat(2, minmax(0, 100%)),
        $mobile: minmax(0, 100%)
      ),
      $columnGapSetup: (
        $above-desktop-xl: 25px
      ),
      $rowGapSetup: (
        $desktop-md: 30px
      )
    );

    @include gridBuilder($grid-setup);
  }
}
</style>
