<template>
  <div class="homepage-forexlive-latest-news__wrapper">
    <a-section-title
      title="Latest News"
      :link="$env.FL_DOMAIN_URL"
      class="homepage-forexlive-latest-news__title"
    />
    <a-visibility hide :on="[$breakpoint.mobile]">
      <div class="homepage-forexlive-latest-news__article-list">
        <article-slot
          v-for="article in articles"
          :key="article.Id"
          :article="article"
        />
      </div>
    </a-visibility>
    <a-visibility show :on="[$breakpoint.mobile]">
      <div class="homepage-forexlive-latest-news__article-list">
        <article-slot
          v-for="article in articlesOnMobile"
          :key="article.Id"
          :article="article"
        />
      </div>
    </a-visibility>
    <a-visibility show :on="[$breakpoint.mobile]">
      <a-button
        :href="$env.FL_DOMAIN_URL"
        class="homepage-forexlive-latest-news__read-all-news"
      >
        Read all news
      </a-button>
    </a-visibility>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import ASectionTitle from '@/components/_homepage/Shared/ASectionTitle'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

const ARTICLE_COUNT_ON_MOBILE = 3

export default {
  name: 'HomepageForexliveLatestNews',
  mixins: [serverCacheKey],
  components: {
    ASectionTitle,
    ArticleSlot: hydrateWhenVisible(
      () => import('shared/ArticleSlots/Fl/Homepage/AArticleSlotLatestNews'),
      { props: ['article'] }
    )
  },
  props: {
    articles: propValidator([PROP_TYPES.ARRAY])
  },
  computed: {
    articlesOnMobile() {
      return this.articles.slice(0, ARTICLE_COUNT_ON_MOBILE)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';
.homepage-forexlive-latest-news__wrapper {
  .homepage-forexlive-latest-news__title /deep/ .homepage-section-title__title {
    color: $c--fl-main;
  }

  .homepage-forexlive-latest-news__article-list {
    $grid-setup: (
      $columnSetup: (
        $above-desktop-xl: repeat(6, minmax(0, 100%)),
        $desktop-xl: repeat(3, minmax(0, 100%)),
        $tablet: repeat(2, minmax(0, 100%)),
        $mobile: minmax(0, 100%)
      ),
      $columnGapSetup: (
        $above-desktop-xl: 25px,
        $desktop-xl: 30px,
        $desktop-lg: 25px,
        $tablet: 30px
      ),
      $rowGapSetup: (
        $desktop-xl: 45px,
        $desktop-lg: 25px
      )
    );

    @include gridBuilder($grid-setup);
  }

  .homepage-forexlive-latest-news__read-all-news {
    width: 200px;
    height: 34px;
    margin: 20px auto 0;
    text-transform: uppercase;
    font-weight: 400;
  }
}
</style>
